const colors = {
  formButtonLoading:"#8a94a4",
  formButtonColor:"#3182ce",

  formErrorColor:"red",
  formColor:"#000",

  formFieldErrorColor:"red",
  formFieldPlaceHolderColor:"#b6bdc7",
  formFieldColor:"#000",
  formFieldFocusColor:"#373536"


};

const fonts = {};

const components = {
  FormLabel: {
    variants: {
      //label
      formLabel: {
        color: '#373536',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '23px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '160%',
      },
    },
  },
  Text: {
    variants: {
      formErrorMessage: {
        color: '#e71515',
        fontFamily: 'Montserrat',
        fontSize: '17px',
        fontWeight: 'bold',
        lineHeight: '160%',
      },
      formCheckboxText: {
        fontSize:'md',
      }
    },
  },
  Button: {
    variants: {
      formButton: {
        textAlign: 'center',
        height:  '50px',
        width: '250px',
        color: 'white',
        size: ['md', 'xl'],
        borderRadius: [30],
      },
    },
  },
  Input: {
    variants: {
      formInput: {
        fontFamily:'Montserrat',
        textAlign:'center',
        fontSize: '25px',
        fontWeight:'700',
        _focus:{ color: '#c04b89' },
        _placeholder:{ color: '#b6bdc7' },
        bg:'white.100',
        maxW:350,
        borderRadius:[2, 15],
      },
    },
  },
  Select: {
    variants: {
      formSelect: {
        fontFamily:'Montserrat',
        textAlign:'center',
        fontSize: '25px',
        fontWeight:'700',
        _focus:{ color: '#c04b89' },
        _placeholder:{ color: '#b6bdc7' },
        bg:'white',
        maxW:350,
        borderRadius:[2, 15],
      },
    },
  },
  NumberInputField: {
    variants: {
      formNumberInput: {
        fontFamily:'Montserrat',
        textAlign:'center',
        fontSize: '25px',
        fontWeight:'700',
        _focus:{ color: '#c04b89' },
        _placeholder:{ color: '#b6bdc7' },
        bg:'white.100',
        maxW:350,
        borderRadius:[2, 15],
      },
    },
  },
  Checkbox:{
    variants: {
      formCheckbox: {
        size:'md',
        colorScheme:"orange",
        borderColor:"#DD6B20",
        textAlign:'left',
      },
    },
  }
};

export { colors, fonts, components };
